@import "base";
@import "components/all";

button.md-button.md-fab {
    position: fixed;
}

md-tab-item {
    color: rgba(255,255,255,0.7) !important
}

md-tab-item.md-active {
    color: rgba(255,255,255, 1) !important
}

md-dialog {
	color: black;
}